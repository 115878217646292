import script from "./Index.vue?vue&type=script&lang=ts&setup=true"
export * from "./Index.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QBtn,QTd});
